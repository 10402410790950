import { API } from '../../services/api';
import { CONTACT_LOGGED_IN, CONTACT_LOGGED_OUT } from './contact.actions';

/*
   Hook into setting changes in order to change layout.
*/
const contact = store => next => action => {
    let result = next(action)

    if (action.type === CONTACT_LOGGED_IN) {
        API.setToken(action.value.access_key);
    } else if (action.type === CONTACT_LOGGED_OUT) {
        API.setToken(null);
    }

    return result
}

export default contact;