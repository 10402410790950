import React from 'react';
import { withRouter } from 'react-router';

import Header from './Header'
import Footer from './Footer'

const Base = props => (
    <div className="wrapper bg-lightest">
        <Header {...props} />

        {/*<Sidebar />*/}

        {/*<section className="section-container">*/}
            {props.children}
        {/*</section>*/}

        <Footer />
    </div>
)

export default withRouter(Base);
