import { TOGGLE_SETTING, CHANGE_SETTING } from './settings.actions';
import { CONTACT_LOGGED_OUT } from '../contact/contact.actions';
import routes from '../../routing/routes';

const initialSettings = {
    /* Nr of agendas next to each other in group overview */
    language: 'nl',
    nrAgendasRowGroupOverview: 3,
    entrance: null,
    overview: {
        route: routes.notfound,
        path: ''
    },
    appointmentTypeInput: null,
    afterLoginUrl: null,
    contactList: {}
};

const settingsReducer = (state = initialSettings, action) => {
    switch (action.type) {
        case TOGGLE_SETTING:
            return {
                ...state,
                [action.name]: !state[action.name]
            }
        case CHANGE_SETTING:
            return {
                ...state,
                [action.name]: action.value
            };
        case CONTACT_LOGGED_OUT:
            return initialSettings;
        default:
            return state;
    }
}

export default settingsReducer;