import { combineReducers } from 'redux';

import agendaReducer from './agenda/agenda.reducer';
import groupReducer from './group/group.reducer';
import contactReducer from './contact/contact.reducer';
import settingsReducer from './settings/settings.reducer';

export default combineReducers({
    agenda: agendaReducer,
    group: groupReducer,
    contact: contactReducer,
    settings: settingsReducer
});