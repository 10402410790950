import { CONTACT_SET, CONTACT_UPDATE, CONTACT_UPDATE_SETTINGS, CONTACT_REMOVE, CONTACT_ACCESS_KEY_SET, CONTACT_ACCESS_KEY_REMOVE, CONTACT_LOGGED_IN, CONTACT_LOGGED_OUT } from './contact.actions';

const contactReducer = (state = null, action) => {
    switch (action.type) {
        case CONTACT_LOGGED_IN:
            return action.value;
        case CONTACT_LOGGED_OUT:
            return null;
        case CONTACT_SET:
        case CONTACT_UPDATE:
            return {
                ...state,
                [action.name]: action.value
            }
        case CONTACT_REMOVE:
            return {
                ...state,
                [action.name]: undefined
            }
        case CONTACT_UPDATE_SETTINGS:
            return {
                ...state,
                [action.name]: {
                    ...state[action.name],
                    settings: action.value
                }
            }
        case CONTACT_ACCESS_KEY_SET:
            return {
                ...state,
                access_key: action.value
            }
        case CONTACT_ACCESS_KEY_REMOVE:
            return {
                ...state,
                access_key: undefined
            }
        default:
            return state;
    }
}

export default contactReducer;