import React, { Component } from 'react';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import routes from '../../routing/routes';
import { UtilUrl } from '../../utils/url.util'

class Footer extends Component {

    render() {
        const year = new Date().getFullYear()
        return (
            <footer className="footer-container">
                <span>
                    &copy; {year} Reservi
                    &nbsp;-&nbsp;
                    Een product van <a href="https://www.sanmax.be" target="_blank" rel="noopener noreferrer">Sanmax Projects</a>
                    &nbsp;-&nbsp;

                    <Link to={{ pathname: UtilUrl.generate(routes.privacypolicy, {}) }}>
                        {t('footer:privacy-policy.text')}
                    </Link>
                    &nbsp;-&nbsp;
                    <a href={t('footer:general-conditions.link')} target="_blank" rel="noopener noreferrer">{t('footer:general-conditions.text')}</a>

                    {/*
                    <Link to={{ pathname: UtilUrl.generate(routes.terms, {}) }}>
                        {t('footer:general-conditions.text')}
                    </Link>*/}

                </span>
            </footer>
        );
    }

}

export default Footer;
