/**
 * Group actions
 */
export const GROUP_UPDATE = 'GROUP_UPDATE';
export const GROUP_REMOVE = 'GROUP_REMOVE';

export function groupUpdate(value) {
    return { type: GROUP_UPDATE, value };
};

export function groupRemove() {
    return { type: GROUP_REMOVE };
};