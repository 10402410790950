import React from 'react';
import { withRouter } from 'react-router';
import { t } from 'i18next';
import ContentWrapper from '../../components/layout/ContentWrapper';
import { Heading } from '../../components';
import { ApiLogService } from '../../services/api/log.service';
import { connect } from 'react-redux';
import { UtilDate } from '../../utils/date.util';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            error: null,
            errorInfo: null
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentWillMount() {
        this.unlisten = this.props.history.listen(() => {
            this.setState({ hasError: false, error: null, errorInfo: null });
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            hasError: true,
            error: error,
            errorInfo: errorInfo
        });

        console.info(error, errorInfo);

        this.sendError(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <ContentWrapper>
                    <Heading title={t('error:title')}></Heading>
                    <p>{t('error:description')}</p>
                </ContentWrapper>
            );
        }

        return this.props.children;
    }

    sendError = (error) => {
        if (process.env.NODE_ENV === 'development') {
            return;
        }

        let title = '';
        if (this.props.group) {
            title += this.props.group.name + ' ';
        }

        if (this.props.agenda) {
            title += this.props.agenda.title;
        }

        ApiLogService.log({
            subject: title,
            error: error.toString(), 
            stack: error.stack,
            time: UtilDate.create().format('YYYY-MM-DD HH:mm:ss')
        });
    }
}

const mapStateToProps = state => ({ agenda: state.agenda, group: state.group })

export default connect(mapStateToProps)(withRouter(ErrorBoundary));