import { AGENDA_UPDATE, AGENDA_REMOVE } from './agenda.actions';
import { CONTACT_LOGGED_OUT } from '../contact/contact.actions';

const agendaReducer = (state = null, action) => {
    switch (action.type) {
        case AGENDA_UPDATE:
            return action.value;
        case AGENDA_REMOVE:
        case CONTACT_LOGGED_OUT:
            return null;
        default:
            return state;
    }
}

export default agendaReducer;