import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { t } from 'i18next';

const ButtonBack = props => (
    <Button color="default" tag={props.tag ? props.tag : Link} to={props.to} className={'btn-labeled ' + props.className}>
        <span className="btn-label"><i className="fa fa-arrow-left" aria-hidden></i></span>
        {props.children ? props.children : t('common:back.to.overview')}
    </Button>
)

export default ButtonBack;
