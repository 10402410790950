import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import moment from 'moment';
import 'moment/min/locales';
import configureStore from './store/store';

const FALLBACK_LANGUAGE = 'nl';

const stateLanguage = () => {
    try {
        return configureStore().getState().settings.language;
    } catch (error) {
        return FALLBACK_LANGUAGE;
    }
};

i18n
    .use(Backend)
    // .use(LanguageDetector)
    .use(reactI18nextModule)
    .init({
        interpolation: {
            format: (value, format, lng) => {
                if (!(value instanceof moment)) {
                    value = moment(value);
                }
                return value.format(format); 
            }
        },
        lng: stateLanguage(),
        fallbackLng: FALLBACK_LANGUAGE,
        backend: {
            loadPath: 'locales/{{lng}}/{{ns}}.json',
        },
        // have a common namespace used around the full app
        ns: [//alfabetisch!
            'appointment',
            'appointment.confirmation',
            'appointment.progress',
            'appointment.type',
            'authentication',
            'calendar',
            'agenda',
            'date',
            'error',
            'footer',
            'group',
            'language',
            'contact.appointment'
        ],
        keySeparator: '/',
        defaultNS: 'common',
        debug: false,
        react: {
            wait: true
        },
        preload: ['nl', 'fr']
    });

i18n.on('languageChanged', (lng) => {
    moment.locale(lng);
});

export default i18n;