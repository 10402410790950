import { reverse } from "named-urls";
import queryString from 'querystring';

const generate = (route, params = {}) => {
    return reverse(route, params);
};

const parseSearchString = (searchStr, key = null) => {
    const parsed = queryString.parse(searchStr);

    if (!key) {
        return parsed;
    }

    if (key in parsed) {
        return parsed[key];
    }
    
    return null;
};

export const UtilUrl = {
    generate,
    parseSearchString
};