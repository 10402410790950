import React from 'react';

const Heading = (props) => (
    <div className={'heading ' + props.className}>
        <div className="heading-start">
            <h1 className="heading-title">{props.title}</h1>
            {renderSubtitle(props)}
        </div>

        {renderEnd(props)}
    </div>
)

const renderSubtitle = (props) => {
    if (!props.subtitle) {
        return;
    }
    return (
        <h2 className={'heading-subtitle ' + props.classNameSubtitle}>{props.subtitle}</h2>
    );
}

const renderEnd = (props) => {
    if (!props.end) {
        return;
    }
    return (
        <div className="heading-end" id="heading-end">
            {props.end}
        </div>
    );
}

export default Heading;
