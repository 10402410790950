/**
 * Group actions
 */
export const AGENDA_UPDATE = 'AGENDA_UPDATE';
export const AGENDA_REMOVE = 'AGENDA_REMOVE';

export function agendaUpdate(value) {
    return { type: AGENDA_UPDATE, value };
};

export function agendaRemove() {
    return { type: AGENDA_REMOVE };
};