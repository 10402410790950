import Heading from './Heading';
import ButtonAdd from './buttons/ButtonAdd';
import ButtonBack from './buttons/ButtonBack';
import ButtonSave from './buttons/ButtonSave';
import ButtonPrint from './buttons/ButtonPrint';
import TableHead from './table/TableHead';
import TableBody from './table/TableBody';

export {
    //default
    Heading,

    //buttons
    ButtonAdd,
    ButtonBack,
    ButtonSave,
    ButtonPrint,

    // Table
    TableHead,
    TableBody
}